import React from "react"
import classnames from "classnames"
import useForm from "./hooks"

const Form = ({
  className,
  defaultValues = {},
  children = null,
  schema = null,
  manually = false,
  reinitialize = false,
  onSubmit = (e) => e,
}) => {
  const {
    form,
    state,
    methods: { reset, resetField, ...others },
    submitter,
  } = useForm({
    defaultValues,
    schema,
    reinitialize,
  })

  const elements = manually ? children : [children].flat()

  if (manually) {
    return (
      <form className={classnames(className)} onSubmit={submitter(onSubmit)}>
        {typeof elements === "function" &&
          elements({ form, state, reset, resetField, ...others })}
      </form>
    )
  }

  return (
    <form className={classnames(className)} onSubmit={submitter(onSubmit)}>
      {elements?.map((child, index) => {
        if (child?.props?.name)
          return React.createElement(child.type, {
            ...{
              ...child.props,
              key: child.props.name,
              form,
            },
          })

        if (typeof child === "function") {
          return React.createElement(
            React.Fragment,
            { key: index },
            child({ state })
          )
        }

        return child
      })}
    </form>
  )
}

export default Form
