import { useEffect } from "react"
import { useForm as useFormHook } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { dissectForm } from "./utils"

const useForm = ({ defaultValues, schema, reinitialize }) => {
  const methods = useFormHook({
    defaultValues,
    mode: "onChange",
    ...(schema && { resolver: yupResolver(schema) }),
  })
  const {
    handleSubmit,
    reset,
    register,
    resetField,
    control,
    formState,
    setValue,
    ...others
  } = methods
  const form = { register, control, setValue }
  const state = dissectForm(formState)

  const submitter = (onSubmit) =>
    handleSubmit((data, event) => {
      onSubmit(data, event, { state, reset, defaultValues, schema })
    })

  useEffect(() => {
    if (reinitialize && Object.keys(defaultValues).length > 0) {
      reset(defaultValues)
    }
  }, [reinitialize, defaultValues, reset])

  return {
    form,
    state,
    methods: { handleSubmit, reset, setValue, resetField, ...others },
    submitter,
  }
}

export default useForm
